@import "./assets/scss/_config.scss";
@import "config";
@import "_includes/_giftcard-client";

.page__container {
  margin: 1rem auto;
  max-width: 800px;
}

.page__container .pagina-title {
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  #page table {
    width: 100% !important;
  }

#page table th {
    width: 40%;
  }

  .page__container {
    padding: 0;
  }
}