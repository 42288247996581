.balance-checker .panel.card-info {
  background-color: white;
  border-color: #e1e1e1;
}

.balance-checker .panel__title {
  margin-bottom: 0;
}

.balance-checker .list__items {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}
